.buttonDiv {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.layoutEditDocProps {
  display: flex;
}

.layoutEditDocProps .docSide {
  width: calc(100% - 250px);
}
.layoutEditDocProps .propSide {
  width: 250px;
  padding-left: 1rem;
}
