.actionButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.column {
  width: 33%;
}
