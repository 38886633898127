.optionsDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.actionColumn {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.largeModal {
  width: 90%;
  max-width: none;
}
.height80px {
  height: 80px;
}
.height20px {
  height: 20px;
}

.height160px {
  height: 160px;
}

.vFieldHeight {
  height: 80px;
}

.buttonDiv {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 3em;
}

.firstRow {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: max-content 1fr 1fr;
}

.secondRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
.thirdRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
}

.footer {
  /* display: flex; */
  width: 100%;
  height: 30svh;
  max-height: 30svh;
  /* bottom: 0; */
}
.header {
  top: 0;
  width: 100%;
}
.content {
  width: 100%;
  max-height: 36svh;
  overflow-y: scroll;
}
