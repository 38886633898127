.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  gap: 3rem;
}

.vFieldHeight {
  height: 80px;
}

.chartArea {
  height: 400px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* aspect-ratio: 2 / 1; */
}
