.flex3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.flex3 > div {
  width: 30%;
}
