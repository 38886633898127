.largeModal {
  width: 90%;
  max-width: none;
}
.height80px {
  height: 80px;
}

.height160px {
  height: 160px;
}

.vFieldHeight {
  height: 80px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.flexButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}
