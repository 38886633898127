.claimantFormButtonDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 1rem;
}

.height50px {
  height: 50px;
}

.vFieldHeight {
  height: 80px;
}
