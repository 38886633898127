.footer {
  display: flex;
  position: static;
  width: 100%;
  height: 5svh;
  max-height: 5svh;
  /* max-height: 10%; */
  /* top: 100; */
  bottom: 0;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
  justify-self: flex-end;
  justify-items: flex-end;
}
.header {
  /* height: 30svh; */
  display: absolute;
  top: 0;
  width: 100%;
  max-height: 70%;
  /* overflow-y: scroll; */

  /* padding-bottom: 30svh; */
}
.content {
  width: 100%;
  /* display:grid; */
  max-height: 60svh;
  overflow-y: scroll;
}
