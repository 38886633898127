.claimDetailTopSecondRowHeight {
  height: auto;
  min-height: 160px;
}

.readonlyTextarea {
  background-color: #e9ecef;
  opacity: 1;
  height: 100%;
}
.readonlyTextarea:focus {
  background-color: #e9ecef;
  opacity: 1;
}

.firstRow {
  /* width: 50%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.claimantEmployerDetails {
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  gap: 1rem;
}

.rightSide {
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  /* gap: 1rem; */
}

.rightSideFirst {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

.rightSideFirstTop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
}
.rightSideFirstBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.rightSideSecond {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 3rem;
}
@media screen and (max-width: 1499px) {
  .rightSide {
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }
  .rightSideSecond {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .firstRow {
    width: 100%;
  }
  .claimantEmployerDetails {
    flex-direction: column;
    justify-content: stretch;
  }
  .claimDetailTopSecondRowHeight {
    height: auto;
  }
  .rightSide {
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }
  .rightSideSecond {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }
}
