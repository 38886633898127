.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}

.buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
}
