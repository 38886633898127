.addEditSettlementTopDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  /* margin: auto; */
}

.formRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  max-width: 750px;
}

.maxWidth700 {
  max-width: 700px;
}

.column33 {
  width: 33%;
}
