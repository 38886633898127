.excessInformationGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  grid-template-rows: auto;
}

.excJustifyEnd {
  justify-self: end;
  font-weight: bold;
}
.excJustifyStart {
  justify-self: start;
}

.claimDetailBottomButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}
.saveCancelBtnGrp {
  justify-self: center;
}

.form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.column {
  width: 50%;
}

@media screen and (max-width: 1200px) {
  .form {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
}
