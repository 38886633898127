.formRow {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.formRowContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  flex-direction: row;
}
.formRow2 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.formWidth {
  max-width: 800px;
  margin: auto;
}

.formRow2 > div {
  width: 100%;
  max-width: 600px;
}

.vFieldHeight {
  height: 80px;
}

.flexGap1rem {
  gap: 1rem;
}

@media screen and (max-width: 1024px) {
  .formRowContainer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
  }
}
