.optionsDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.actionColumn {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

tr:has(div.hasPendingNoteRequests) {
  background-color: yellow;
}
