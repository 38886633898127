.largeModal {
  width: 90%;
  max-width: none;
}

.grid7 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
}
.grid8 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  /* align-items: center; */
}
