.pageTop {
  /* height: 125px; */
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  gap: 3rem;
}

.grid2 {
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  column-gap: 1rem;
  padding-bottom: 3rem;
}

.selectedClaimGrid {
  display: grid;
  margin: auto;
  grid-template-columns: max-content max-content;
  column-gap: 1rem;
}

.outerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.outerGrid > button {
  align-self: center;
  justify-self: center;
}

.topDocGrid {
  display: grid;
  grid-template-columns: max-content max-content max-content 1fr;
  column-gap: 1rem;
  align-items: center;
}

.docGrid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  /* align-items: center; */
}

.vFieldHeight {
  height: 80px;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
}
