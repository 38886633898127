.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.vFieldHeight {
  height: 80px;
}

.viewTemplateButton {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.viewTemplateButton:hover {
  background: #e0e1e6;
}

.templateOptions {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
}

.templateOptions {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
}

.templateOptions:hover {
  background: #e0e1e6;
}

.modalBackground {
  background: rgba(0, 0, 0, 0.4);
}
.noteContainer div:nth-child(2) {
  min-height: 150px;
  font-size: large;
}

.noteContainer div:nth-child(2) > div {
  min-height: 150px;
  font-size: large;
}
