.largeModal {
  width: 90%;
  max-width: none;
}
.height80px {
  height: 80px;
}

.height160px {
  height: 160px;
}

.vFieldHeight {
  height: 80px;
}

.buttonDiv {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 3rem;
}
