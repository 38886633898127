.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  gap: 3rem;
}
.grid4 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  column-gap: 1rem;
}
.grid4 > p {
  justify-self: flex-end;
}

.grid5 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  column-gap: 1.5rem;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.chartArea {
  max-height: 800px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  aspect-ratio: 2 / 1;
}

.radarChart {
  max-height: 600px;
  aspect-ratio: 1 / 1;
}
