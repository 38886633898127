.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
}

.pageTop img {
  max-width: 300px;
}

.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
}
.grid4 p {
  margin-bottom: 0;
}

.grid2 {
  margin: auto;
  width: 416px;
  display: grid;
  grid-template-columns: 200px 200px;
  column-gap: 1rem;
}
.grid2 p:nth-child(odd) {
  justify-self: right;
}
