.detailGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.detailGrid .span:nth-child(even) {
  align-items: flex-start;
}
