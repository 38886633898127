.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  gap: 3rem;
}

.largeModal {
  max-width: none;
  width: 90%;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
