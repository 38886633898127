.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  gap: 3rem;
}

.flex2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.flex2 > div {
  width: 45%;
}

.flex3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.flex3 > div {
  width: 30%;
}

.textEditor {
  height: 200px;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
}

.letterSide {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttonDiv {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.largeModal {
  width: 90%;
  max-width: none;
}
.centerContent {
  max-width: 1100px;
  margin: auto;
}

.topWindow {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.buttonColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.maxHeight300 {
  max-height: 300px;
}

.documentWindow {
  max-width: 800px;
  min-width: 600px;
  min-height: 300px;
}

.envelopeWindow {
  max-width: 800px;
  min-width: 600px;
  height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.letterOptionButtons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.modalBackground {
  background: rgba(0, 0, 0, 0.4);
}

.noteContainer div:nth-child(2) {
  min-height: 450px;
  font-size: large;
}

.noteContainer div:nth-child(2) > div {
  min-height: 450px;
  font-size: large;
}
