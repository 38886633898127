.optionsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  margin-bottom: 2rem;
}

.optionsGrid > div {
  justify-self: center;
}

.reportOptionsButtonText {
  font-size: 0.9rem;
  text-align: center;
}
.reportOptionsButtonText p {
  margin: 0;
}
