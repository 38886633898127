.confirmButtonsDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 1rem;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
}
