.actionButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.addEditTopDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  /* margin: auto; */
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.excJustifyEnd {
  justify-self: end;
  font-weight: bold;
}
.excJustifyStart {
  justify-self: start;
}

.saveCancelBtnGrp {
  justify-self: center;
}

.form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.column {
  width: 50%;
}

.column33 {
  width: 33%;
}

.column66 {
  width: 66%;
}

@media screen and (max-width: 1200px) {
  .form {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
}

.hide {
  display: none;
}
