.largeModal {
  width: 90%;
  max-width: none;
}

.headerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.grid4Columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  align-items: center;
}
.grid3Columns {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr;
  column-gap: 2rem;
  align-items: center;
}

.medicalRemarksGrid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 2rem;
  align-items: center;
}

.grid5Columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  column-gap: 2rem;
  align-items: center;
}

.grid4ShortFirst {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  column-gap: 2rem;
  align-items: center;
}

.gridLetterField {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
}

.width800px {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
