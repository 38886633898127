.buttonDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.address3,
.flexGap1rem {
  gap: 1rem;
}
.address3children > div {
  flex: 1 0 30%;
}

.width35 {
  width: 35%;
}
