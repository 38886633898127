.width-300 {
  max-width: 300px;
}

.datePickerButton {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.finalBsRenderGrid {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(75px, 130px) 3fr;
  grid-template-rows: 55px;
}

.finalBsRenderGridTextarea {
  display: grid;
  grid-template-columns: minmax(75px, 130px) 3fr;
  grid-template-rows: 95px;
}

.LoadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* color: $synapse-gray; */
  background-color: rgba(255, 251, 251, 0.7);
  z-index: 12;
  font-size: 50px;
  text-align: center;
}

.fieldError,
.fieldWarning,
.fieldNote {
  position: relative;
  font-size: 0.8rem;
}

.vFieldHeight {
  height: 80px;
}

.smallFont {
  font-size: small;
}

.resizer {
  right: 0;
  background: #dadcdd;
  width: 3px;
  height: 100%;
  position: absolute;
  cursor: col-resize;
  top: 0;
  z-index: 1;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

.front {
  z-index: 9999 !important;
}
