.topRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.actionColumn {
  display: flex;
  width: 345px;
  justify-content: center;
  gap: 0.5rem;
  overflow: auto;
}
