.addEditSettlementTopDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  /* margin: auto; */
}

.formRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  max-width: 750px;
}

.maxWidth750 {
  max-width: 750px;
}

.actionButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
