.topToolbar button {
  margin-right: 1rem;
}

.largeModal {
  width: 90%;
  max-width: none;
}

.width2rem {
  width: 2rem;
}

.textNotification {
  position: absolute;
  background-color: red;
  border-radius: 10px;
  color: white;
  translate: 75px -10px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  place-items: center;
}
