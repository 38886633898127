.address3,
.flexGap1rem {
  gap: 1rem;
}
.address3children > div {
  flex: 1 0 30%;
}
.width33 {
  padding: 0 1rem;
  width: 33%;
}
.claimantFormButtonDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 1rem;
  padding-top: 8px;
}

.noteContainer div:nth-child(2) {
  min-height: 200px;
  font-size: large;
}

.noteContainer div:nth-child(2) > div {
  min-height: 200px;
  font-size: large;
}

.textBackground {
  background: rgb(240 240 240);
}
.fromMessage {
  /* width: 75%; */
  max-width: 75%;
  text-align: start;
  align-self: start;
  margin: 2px;
  margin-left: 5px;
}
.fromMessageBody {
  padding: 5px;
  border: 2px solid rgb(255 255 255);
  border-radius: 5px;
  background: rgb(255 255 255);
  word-break: break-word;
}
.toMessage {
  /* width: 75%; */
  max-width: 75%;
  text-align: end;
  align-self: end;
  margin: 2px;
  margin-right: 5px;
}
.toMessageBody {
  padding: 5px;
  border: 2px solid rgb(173 216 230);
  border-radius: 5px;
  background: rgb(173 216 230);
  word-break: break-word;
}
.messageDate {
  font-style: italic;
  font-size: 12px;
}
.pageHeight {
  height: calc(100% - 200px);
}
.pageTop {
  height: 100px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
}
.letterSide {
  width: 50%;
  height: 600px;
  display: flex;
  flex-direction: column;
  position: sticky;
  right: 0;
  top: 0;
  /* justify-content: space-between; */
}
.formSubmit {
  width: 100%;
  height: 100px;
}
.textTopInfo {
  width: 100%;
  height: 75px;
}
#chatCon {
  width: 20%;
  position: absolute;
  bottom: 3%;
  right: 3%;
}

#chatCon .chat-box {
  /* display: none; */
  width: 100%;
  height: 500px;
  border-radius: 25px;
  background-color: #eee;
}

#chatCon .chat-box .header {
  background-color: #3fda73;
  padding: 15px;
  border-radius: 20px 20px 0 0;
  color: #fff;
  font-size: 20px;
}

#chatCon .chat-box .msg-area {
  overflow: hidden;
  height: 370px;
  padding: 15px;
}

#chatCon .chat-box .msg-area .left span {
  display: inline-block;
  font-size: 17.5px;
  border-radius: 15px;
  padding: 15px;
  background-color: #ddd;
}

#chatCon .chat-box .msg-area .right {
  text-align: right;
}

#chatCon .chat-box .msg-area .right span {
  display: inline-block;
  font-size: 17.5px;
  border-radius: 15px;
  padding: 15px;
  background-color: #ddd;
}

#chatCon .chat-box .footer {
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#chatCon .chat-box .footer input {
  border: 1px solid #fff;
  padding: 10px;
  width: 80%;
  border-radius: 15px;
}

#chatCon .chat-box .footer input:foucs {
  outline: none;
}

#chatCon .chat-box .footer button {
  border: none;
  font-size: 22.5px;
  color: lightgreen;
  cursor: pointer;
}

#chatCon .pop {
  width: 100%;
  height: 25%;
  cursor: pointer;
}

#chatCon .pop p {
  text-align: right;
}

#chatCon .pop img {
  border-radius: 50%;
  width: 25%;
}

.viewTemplateButton {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.viewTemplateButton:hover {
  background: #e0e1e6;
}

.templateOptions {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
}

.templateOptions {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
}

.templateOptions:hover {
  background: #e0e1e6;
}
.textTopInfoPopup {
  width: 100%;
  height: 100px;
}
.popupWrapper {
  position: absolute;
}
.popup {
  background: white;
  width: 400px;
  height: 600px;
  display: flex;
  flex-direction: column;
  /* position: fixed; */
  right: 0;
  bottom: 0;
  padding: 10px;
  border: solid grey 1px;
}
.popupMinimizedWrapper {
  background: white;
  width: 250px;
  display: flex;
  align-self: end;
  /* position: fixed; */
  right: 0;
  bottom: 0;
  border: solid grey 1px;
  border-radius: 10px 0px 0px 0px;
  cursor: pointer;
}
.popupMinimized {
  width: 250px;
  /* position: fixed; */
  right: 0;
  bottom: 0;
  padding: 10px;
  cursor: pointer;
}
.popupMinimized:hover {
  background: grey;
  color: white;
  border-radius: 10px 0px 0px 0px;
}
.closePopup {
  display: flex;
  justify-content: end;
  cursor: pointer;
}
