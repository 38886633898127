.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  gap: 3rem;
}

.max800 {
  max-width: 800px;
  margin: auto;
  margin-top: 1.5rem;
  padding-bottom: 3rem;
}

.grid2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 1rem;
  align-items: center;
}

.grid3 {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  column-gap: 1rem;
  align-items: center;
}
