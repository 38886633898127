.pdfNavigation {
  max-width: 300px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.pdfNavigation button {
  width: 100px;
}

.pdfNavBesidePdf {
  display: flex;
}

.pdfThumbnailContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100px;
}

.pdfThumbnail {
  width: 80px;
  margin: 8px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  overflow: hidden;
}

.pdfThumbnail:hover {
  border: 1px solid #0dcaf0;
}

.pdfThumbnail_selected {
  width: 80px;
  margin: 8px;
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid #0b5ed7;
}

.width_navigation {
  /* width: 1370px; */
  margin: auto;
}

.width {
  /* width: 1224px; */
  margin: auto;
}
.noResults {
  margin: 2rem 0 2rem 0;
  padding: 0;

  font-size: 1.25em;
  color: #ececec;
  text-shadow: 0 -1px 0 #cdcdcd;
  text-align: center !important;
  user-select: none;
  cursor: default;
}
