.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-content: flex-start;
  gap: 3rem;
}

.chartArea {
  height: 350px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
  /* aspect-ratio: 2 / 1; */
}
