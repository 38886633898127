.largeModal {
  width: 90%;
  max-width: none;
}

.chartArea {
  max-height: 800px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  aspect-ratio: 2 / 1;
}
