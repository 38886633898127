.recurringGrid {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 4fr;
  column-gap: 3rem;
}

.weekChecks {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}

.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}
.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 1200px) {
  .recurringGrid {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 4fr;
    column-gap: 1rem;
  }
  .weekChecks {
    gap: 1rem;
  }
}
@media screen and (max-width: 1000px) {
  .recurringGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  .weekChecks {
    gap: 1rem;
  }
}
