.statusReview {
  max-width: 800px;
  margin: auto;
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}
