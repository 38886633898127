/* @import-normalize; */
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-datepicker/dist/react-datepicker.css';

html {
  height: 100vh;
  margin: 0;
}

body {
  width: 100%;
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset {
  min-width: initial;
  padding: initial;
  margin: initial;
  border: initial;
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;
  border-radius: 5px;
}

legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;

  float: none;
  width: auto;
  padding: initial;
  margin-bottom: 0;
  font-size: initial;
  line-height: inherit;
}

.root {
  height: 100vh;
  width: 100%;
}

a {
  text-decoration: none;
}

.gap1Rem {
  gap: 1rem;
}

/* Bootstrap overrides */
.tab-content {
  width: 100%;
  height: auto;
}
.tab-pane {
  width: 100%;
  height: auto;
}

.form-control:read-only {
  cursor: not-allowed;
}
.form-control:read-only:focus {
  cursor: not-allowed;
  box-shadow: none;
  border-color: #ced4da;
}

.popover {
  max-width: none;
}

/* react-datepicker */
.react-datepicker-wrapper {
  width: auto;
}
.react-datepicker {
  display: inline-flex;
}

.button-icon-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.input-group .btn {
  z-index: 1;
}

/* approve payments row colors */
tr:has(div.orange) {
  background-color: orange;
}
tr:has(div.orange) td {
  background-color: orange;
  color: black;
}

tr:has(div.salmon) {
  background-color: salmon;
}
tr:has(div.salmon) td {
  background-color: salmon;
  color: black;
}

tr:has(div.khaki) {
  background-color: khaki;
}
tr:has(div.khaki) td {
  background-color: khaki;
  color: black;
}

tr:has(div.lightgreen) {
  background-color: rgb(0, 200, 0);
}
tr:has(div.lightgreen) td {
  background-color: rgb(0, 200, 0);
  color: black;
}

div.orange {
  background-color: orange;
}

div.salmon {
  background-color: salmon;
}

div.yellow {
  background-color: yellow;
}
tr:has(div.yellow) {
  background-color: yellow;
}
tr:has(div.yellow) td {
  background-color: yellow;
  color: black;
}

div.khaki {
  background-color: khaki;
}

div.cyan {
  /* background-color: cyan; */
}
tr:has(div.cyan) {
  background-color: cyan;
}
tr:has(div.cyan) td {
  background-color: cyan;
  color: black;
}
div.lightgreen {
  background-color: rgb(0, 200, 0);
}
@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1400px;
  }
}
@media (min-width: 1400px) {
  .modal-xl {
    --bs-modal-width: 1600px;
  }
}
