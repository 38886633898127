.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.vFieldHeight {
  height: 80px;
}
