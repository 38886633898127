.actionColumn {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.largeModal {
  width: 90%;
  max-width: none;
}
