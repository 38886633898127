.gridSurround {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  height: 741px;
}

.nextVisit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.vFieldHeight {
  height: 80px;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.fieldSet {
  overflow-y: scroll;
  height: 741px;
}
