.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  gap: 3rem;
}
.optionsDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.actionColumn {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
