.mainScreen {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  position: relative;
}

.loginHeader {
  background-color: #0f2436;
}

.logoDiv {
  width: 350px;
}

.loginForm {
  width: 300px;
}

.gasBlue {
  background-color: #0f2436;
  color: #fff;
}
