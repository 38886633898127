.noResults {
  margin: 2rem 0 2rem 0;
  padding: 0;

  font-size: 1.25em;
  color: #ececec;
  text-shadow: 0 -1px 0 #cdcdcd;
  text-align: center !important;
  user-select: none;
  cursor: default;
}
