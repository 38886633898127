.largeModal {
  width: 90%;
  max-width: none;
}

.confirmButtonsDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.grid3 {
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
}
.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
}
