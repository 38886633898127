.ediInfoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.ediInfoGrid > p {
  margin-bottom: 0;
}

.ediTypesGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.5rem;
  align-items: center;
}

.edi3Grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}
.edi2Grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.noRowGap {
  row-gap: 0;
}

.largeModal {
  width: 90%;
  max-width: none;
}

.grid7 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  /* row-gap: 1rem; */
  align-items: center;
}

.grid7 > div {
  height: auto;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.grid2 > div {
  height: auto;
}
