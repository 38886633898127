.officeGrid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 1rem;
  align-items: center;
}

.buttonDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}
