.topGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  align-items: center;
}

.enableAchGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 2rem;
  align-items: center;
}

.width33 {
  padding: 0 1rem;
  width: 33%;
}

.width50 {
  width: 50%;
}
.width100 {
  width: 100%;
  padding: 0 1rem;
}
.address3 {
  gap: 1rem;
}
.address3children div {
  flex: 1 0 30%;
}

.largeModal {
  width: 50%;
  max-width: none;
}

.claimantFormButtonDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 1rem;
}

.flexGap1rem {
  gap: 1rem;
}
