.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
}
.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
}
.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  padding: 2rem;
}

.largeModal {
  width: 90%;
  max-width: none;
}
.vFieldHeight,
.height80px {
  height: 80px;
}

.or {
  justify-self: center;
  align-self: center;
  font-weight: bold;
}
