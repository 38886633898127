.pageTop {
  height: 125px;
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
}

.pageTop img {
  max-width: 300px;
}

.grid2 {
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  column-gap: 1rem;
  padding-bottom: 3rem;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
}

.height110 {
  height: 110px;
}
