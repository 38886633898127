.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.vFieldHeight {
  height: 80px;
}

.officeGrid {
  display: grid;
  grid-template-columns: 5fr 1fr;
  column-gap: 1rem;
  align-items: center;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
}
