.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.vFieldHeight {
  height: 80px;
}

.alertPos {
  position: relative;
  top: -300px;
}

.secureShareDocumentSelect {
  max-height: 600px;
  overflow-y: scroll;
}

.pageTop {
  /* height: 125px; */
  display: grid;
  grid-template-columns: max-content max-content auto;
  justify-content: flex-start;
  gap: 3rem;
}
