.address3,
.flexGap1rem {
  gap: 1rem;
}
.address3children > div {
  flex: 1 0 30%;
}
.width33 {
  padding: 0 1rem;
  width: 33%;
}
.claimantFormButtonDiv {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 1rem;
}

.noteContainer div:nth-child(2) {
  min-height: 200px;
  font-size: large;
}

.noteContainer div:nth-child(2) > div {
  min-height: 200px;
  font-size: large;
}
